import React from 'react'

const Whatsapp = () => {
    return (
        <div className='whatsapp'>
            <a href="https://wa.me/919560927047">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 64 64">
<circle cx="32" cy="32" r="23" fill="#98c900"></circle><ellipse cx="32" cy="61" opacity=".3" rx="19" ry="3"></ellipse><path fill="#fff" d="M32,14c2.577,0,4.674-1.957,4.946-4.461C35.352,9.19,33.699,9,32,9 C19.297,9,9,19.297,9,32c0,1.699,0.19,3.352,0.539,4.946C12.044,36.674,14,34.577,14,32C14,22.075,22.075,14,32,14z" opacity=".3"></path><path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M15.047,23.427c1.878-3.699,4.932-6.705,8.666-8.522"></path><path d="M54.461,27.054C51.956,27.326,50,29.423,50,32c0,9.925-8.075,18-18,18 c-2.577,0-4.674,1.957-4.946,4.461C28.648,54.81,30.301,55,32,55c12.703,0,23-10.297,23-23C55,30.301,54.81,28.648,54.461,27.054z" opacity=".15"></path><path fill="#98c900" d="M25,52l-13.183,3.877c-1.648,0.485-3.179-1.047-2.695-2.695L13,40L25,52z"></path><path fill="#fff" d="M27.105,36.889c-9.135-9.135-5.665-13.845-4.378-15.131c1.007-1.007,3.012-1.007,4.018,0	c0.049,0.049,2.296,2.296,2.296,2.296c1.007,1.007,1.722,2.87,0.574,4.018L28.7,28.789c-0.556,0.556-0.72,1.334-0.227,1.947	c0.589,0.733,1.39,1.618,2.279,2.506c0.889,0.888,1.697,1.612,2.429,2.201c0.612,0.493,1.466,0.402,2.021-0.153l0.728-0.903	c1.007-1.007,2.437-1.007,3.445,0c0,0,2.822,2.822,2.87,2.87c1.007,1.007,1.007,3.012,0,4.018	C40.958,42.562,36.24,46.023,27.105,36.889z"></path>
</svg>
            </a>
        </div>
    )
}

export default Whatsapp