import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/Home.scss'
import About from './Components/Pages/About';
import Home2 from './Components/Pages/Home2';
import Contact from './Components/Pages/Contact';
import RecruitmentService from './Components/Pages/Services/RecruitmentService';
import Temporary from './Components/Pages/Services/Temporary';
import Chaueffer from './Components/Pages/Services/Chaueffer';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home2 />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/service/it-nonit-service" element={<RecruitmentService />} />
          <Route path="/service/temporary-staffing-solutions" element={<Temporary />} />
          <Route path="/service/driver-hiring-and-management" element={<Chaueffer />} />



          {/* <Route path="/product/kachi-ghani-mustard-oil" element={<MustardOil />} />
          <Route path="/product/refined-soyabean-oil" element={<RefinedOil />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
