import React, { useState } from 'react'
import { Link as Links, Element } from 'react-scroll';
import { Link } from 'react-router-dom'; import logo from "../Image/logo/ORIGINAL.png"

import "../Sass/Home.scss"

const Header = () => {


    const [display, setDisplay] = useState("none");

    const show = () => {
        setDisplay("block");
    }

    const hide = () => {
        setDisplay("none");
    }

    const up = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setDisplay("none");
    }

    const [animation, setAnimation] = useState("zoom-in-zoom-out 2s ease-out 1");

    const down = () => {
        window.scrollTo({ top: 10000, left: 0 });
        setDisplay("none");

        if (animation === "zoom-in-zoom-out 2s ease-out 1") {
            setAnimation("none");
        }
        else {
            setAnimation("zoom-in-zoom-out 2s ease-out 1");
        }
    }

    const hidden = () => {
        setDisplay("none");
    }


    // header

    const [navBg, setNavBg] = useState("");
    const [shadow, setShadow] = useState("");
    const [position, setPosition] = useState("");
    const [left, setLeft] = useState("");
    const [top, setTop] = useState("");
    const [boxshadow, setBoxshadow] = useState("");
    const [padding, setPadding] = useState("");
    const [bg, setBg] = useState("");
    const [radius, setRadius] = useState("");
    const [displayProduct, setDisplayProduct] = useState("");

    const changeNavBg = () => {
        window.scrollY >= 200 ? setNavBg("#e9e4d0") : setNavBg("transparent");
        window.scrollY >= 200 ? setShadow("2px 2px 5px rgba(0,0,0,0.3)") : setShadow("none");
        window.scrollY >= 200 ? setPosition("fixed") : setPosition("none");
        window.scrollY >= 200 ? setLeft("5%") : setLeft("5%");
        window.scrollY >= 200 ? setTop("0") : setTop("0");
        window.scrollY >= 200 ? setBoxshadow("#2C2417") : setBoxshadow("#fff");
        window.scrollY >= 200 ? setRadius("0 0 300px 300px") : setRadius("0");
        window.scrollY >= 200 ? setBg("#fff") : setBg("transparent");
        window.scrollY >= 200 ? setPadding("2% 0") : setPadding("1% 0");
    }

    window.addEventListener('scroll', changeNavBg);
    // header ends

    const openproduct = () => {
        setDisplayProduct("flex");
    }

    return (
        <div>  <header>
            <div className="logo">
                <img rel='preload' fetchpriority="high" src={logo} alt="logo" />
                {/* <h1>Collared</h1> */}

            </div>
            <div className="links">
                <Link to={"/"} onClick={up}>Home</Link>
                <Link to={"/about"}>About Us</Link>


                <Link to={"#"} onClick={openproduct}>Services</Link>
                {/* <Link >Products</Link>*/}
                <div className="prod" style={{ display: displayProduct }}>
                    <Link to={"/service/it-nonit-service"}>IT/Non-IT Hiring Services</Link>
                    <Link to={"/service/driver-hiring-and-management"}>Driver Hiring and Management</Link>
                    {/* <Link to={"/service/temporary-staffing-solutions"}>Temporary Staffing Solutions</Link> */}
                </div>
                <Link to={"/contact"}>Contact Us</Link>


            </div>

            <div id='navbar-mobile' style={{ display: display }}>
                <svg className='cross' onClick={hide} id="Capa_1" enable-background="new 0 0 320.591 320.591" height="512" viewBox="0 0 320.591 320.591" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z" /><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z" /></g></g></svg>

                <div className="links">
                    <Link to={"/"} onClick={up}>Home</Link>
                    <Link to={"/about"}>About Us</Link>

                    <Link to={"#"} onClick={openproduct}>Services</Link>
                {/* <Link >Products</Link>*/}
                <div className="prod" style={{ display: displayProduct }}>
                <Link to={"/service/it-nonit-service"}>IT/Non-IT Hiring Services</Link>
                <Link to={"/service/driver-hiring-and-management"}>Driver Hiring and Management</Link>
                </div>
                <Link to={"/contact"}>Contact Us</Link>

                </div>
            </div>
            <svg className='menu' onClick={show} id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m512 256.002c0 17.673-14.327 32-32 32h-307.059c-17.673 0-32-14.327-32-32s14.327-32 32-32h307.059c17.673 0 32 14.327 32 32zm-339.059-134.55h307.059c17.673 0 32-14.327 32-32s-14.327-32-32-32h-307.059c-17.673 0-32 14.327-32 32s14.328 32 32 32zm307.059 269.096h-307.059c-17.673 0-32 14.327-32 32s14.327 32 32 32h307.059c17.673 0 32-14.327 32-32s-14.327-32-32-32zm-419.195-333.096h-28.805c-17.673 0-32 14.327-32 32s14.327 32 32 32h28.805c17.673 0 32-14.327 32-32s-14.327-32-32-32zm0 166.551h-28.805c-17.673 0-32 14.327-32 32s14.327 32 32 32h28.805c17.673 0 32-14.327 32-32s-14.327-32-32-32zm0 166.542h-28.805c-17.673 0-32 14.327-32 32s14.327 32 32 32h28.805c17.673 0 32-14.327 32-32s-14.327-32-32-32z" /></svg>


        </header>
        </div>
    )
}

export default Header