import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'

import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contact = () => {

    // contact form

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Message: "",
        Products: ""
    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Message, Products } = userData;
        // if (Name && Email && Mobile && Message && Products) {

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(userData.Email)) {
            toast.warning('Please enter a valid email address');
        }

        if (typeof Mobile !== "undefined") {

            var pattern = new RegExp(/^[0-9\b]+$/);

            if (!pattern.test(userData.Mobile)) {
                toast.warning("Please enter only number.");
                return

            } else if (userData.Mobile.length !== 10) {
                toast.warning("Please enter 10 digit Mobile number.");
                return
            }
        }

        try {

            // CF.add({
            //     Name: Name,
            //     Email: Email,
            //     Mobile: Mobile,
            //     Message: Message,
            //     Products: Products
            // }).then((docRef) => {
            //     toast.success('Message Submitted Successfully');
            // }).catch((error) => {
            //     console.error("error:", error);
            // });

            emailjs.sendForm('service_p0s37mq', 'template_aicv9xa', form.current, 'cHZgYgYejM7IUmFyo')
                .then((result) => {
                    console.log(result.text);

                }, (error) => {
                    console.log(error.text);
                });
            console.log("Products:" + Products)

            setUserData({
                Name: "",
                Email: "",
                Mobile: "",
                Message: "",
                Products: ""
            })
            toast.success('Message Submitted Successfully');
            // console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
            toast.warning("Error adding document")
        }
        // }
        // else {
        //     toast.warning('Please fill the data');
        // }
    };

    // contact form ends


    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className='contact-page'>
            <Header />
            <ToastContainer />
            <div className="page-banner">
                <h3>Contact Us</h3>
                <h6><Link to={"/"} >Home</Link>&nbsp;&#x203A; Contact us</h6>
            </div>

            <section className="contact" id="buy">
                <div className="contact-body">
                    <div className="contact-left">
                        <div className="contact-left-cont">
                            <span>Call Us</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 32 32"><g id="Layer_3" data-name="Layer 3"><path d="M16,1A15,15,0,1,0,31,16,15,15,0,0,0,16,1Zm.643,9.214a5.149,5.149,0,0,1,5.143,5.143H20.5A3.861,3.861,0,0,0,16.643,11.5ZM20.808,25c-.039,0-3.9-.066-8.865-4.943C7.066,15.094,7,11.231,7,11.192,7,10.711,10,7,10.663,7c.23,0,.409.156.605.39.472.562,2.559,3.856,2.463,4.405-.046.258-.265.454-.893.981a7.416,7.416,0,0,0-.866.8,6.906,6.906,0,0,0,.566,1.526A9.435,9.435,0,0,0,16.9,19.462a6.906,6.906,0,0,0,1.526.566,7.416,7.416,0,0,0,.8-.866c.527-.628.723-.847.981-.893.549-.1,3.843,1.991,4.405,2.463.234.2.39.375.39.605C25,22,21.289,25,20.808,25Zm2.906-9.643a7.079,7.079,0,0,0-7.071-7.071V7A8.367,8.367,0,0,1,25,15.357Z" /></g></svg>
                            <div>
                                <a href="tel:+919560927047">+91 95609 27047</a>
                            </div>
                        </div>
                        <div className="contact-left-cont">
                            <span>Email Us</span>
                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M1.225,8.467C1.331,8.344,8.48,1.449,8.48,1.449a5.026,5.026,0,0,1,7.055.015s7.134,6.88,7.239,7l-8.653,8.654a3.074,3.074,0,0,1-4.242,0Zm14.31,10.069a5.024,5.024,0,0,1-7.07,0L.229,10.3A4.962,4.962,0,0,0,0,11.708V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V11.708a4.962,4.962,0,0,0-.229-1.408Z" /></svg>
                            <div>
                                <a href="mailto:vcmentp@gmail.com">vcmentp@gmail.com</a><br />
                                <a href="mailto:office@vcmrecruitments.com">office@vcmrecruitments.com</a>

                            </div>
                        </div>
                        <div className="contact-left-cont">
                            <span>Location</span>
                            <div>
                                <h3>Home Branch</h3>
                                <p>#361 Basai sector 9B ,Near fly Over Basai ,Gurgaon (Haryana)</p>

                                <h3>Branch Offices</h3>
                                <ol>
                                    <li>Banipur choke, bawal road
                                        Imt bawal, opp union bank</li>
                                    <li>Office no 223, second floor, ARG Group North Avenue, Road no 9A, VKI Area, Sikar Road, Jaipur (Rajasthan)</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <form action="POST" ref={form} onSubmit={SubmitData}>
                        <h3>Send us a message</h3>
                        <p>Feel free to reach out to us with any questions, inquiries you may have.</p>
                        <div className="form">
                            <input type="text" placeholder='Full Name'
                                name='Name'
                                value={userData.Name}
                                onChange={postUserData}
                                required
                            />
                            <input type="email" placeholder='Email Address'
                                name='Email'
                                value={userData.Email}
                                onChange={postUserData}
                                required
                            />
                            <input type="text" placeholder='Mobile'
                                name='Mobile'
                                value={userData.Mobile}
                                onChange={postUserData}
                                required
                            />
                            <input style={{ display: "none" }}
                                type="text"
                                name="productName"
                                value="Contact Us"
                            />
                            <select id="" className="form-control" name='Products'
                                value={userData.Products}
                                onChange={postUserData} >
                                <option value="Select Service" selected hidden>Select Service</option>
                                <option value="IT/Non-IT Hiring Services">IT/Non-IT Hiring Services</option>
                                <option value="Driver Hiring and Management">Driver Hiring and Management</option>
                            </select>
                            <textarea id="" placeholder='Message' name='Message' value={userData.Message}
                                onChange={postUserData}></textarea>
                        </div>

                        <button >Send</button>
                    </form>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Contact