import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import about from "../Image/About/about5-image1.png"
import about2 from "../Image/About/about5-image2.png"
import mission from "../Image/About/mission.jpg"
import arrow from '../Image/About/arrow.png'

const About = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className='about-page'>
            <Header />

            <div className="page-banner">
                <h3>About Us</h3>
                <h6><Link to={"/"} >Home</Link>&nbsp;&#x203A; About us</h6>
            </div>

            <section className="about" id='about'>
                <div className="about-body">
                    <div className="about-img">
                        <img src={about} alt="" className='img1' />
                        <img src={about2} alt="" className='img2' />
                    </div>
                    <div className="about-text">
                        <h3>Transforming Businesses,
                            Elevating Careers</h3>
                        <p>VCM Recruitment is a leading recruitment and placement firm dedicated to connecting talented individuals with rewarding career paths. We specialize in matching skilled professionals with suitable job opportunities across diverse industries.</p>
                        <ul>
                            <li>We prioritize your career aspirations and work diligently to find the ideal job match that aligns with your skills, experience, and goals.</li>
                            <li>We collaborate with reputable companies to source top-notch talent and help them build high-performing teams.</li>
                            <li>We possess in-depth knowledge of various industries, enabling us to identify the best opportunities for our candidates.</li>
                        </ul>

                        <p>Based in Gurgaon, VCM Recruitment specializes in connecting
                            exceptional talent with leading organizations. Our dedicated
                            team leverages extensive industry expertise and a vast network
                            of professionals to deliver customized recruitment solutions
                            tailored to your specific needs.</p>

                        <p>Whether you’re looking to build a high-performing team or
                            seeking your next career opportunity, VCM Recruitment is
                            committed to providing efficient and high-quality service. Let us
                            partner with you to achieve your goals with precision and
                            excellence.</p>
                    </div>
                </div>
            </section>

            <section className="strength">
                <div className="title">
                    <span>Our company strength</span>
                    <h4>Strength That Endures</h4>
                    <p></p>
                </div>
                <div className="strength-body">
                    <div className="strength-card">
                        <h3>Specialized Expertise</h3>
                        <p>VCM Recruitment brings years of experience and industry-specific knowledge to
                            tailor the recruitment process, ensuring a perfect match for your hiring needs.</p>
                    </div>
                    <div className="strength-card">
                        <h3>Extensive Candidate Network</h3>
                        <p>With access to a vast pool of potential candidates and strong industry
                            connections, VCM facilitates quicker and more effective recruitment,
                            streamlining your hiring process.</p>
                    </div>
                    <div className="strength-card">
                        <h3>Rigorous Screening Processes</h3>
                        <p>Our comprehensive screening and assessment techniques guarantee that
                            only the most qualified candidates are presented, ensuring high-quality
                            placements.</p>
                    </div>
                    <div className="strength-card">
                        <h3>Time and Cost Efficiency</h3>
                        <p>By managing the entire recruitment process, VCM saves your business
                            valuable time and resources, allowing you to concentrate on your core
                            operations.</p>
                    </div>
                    <div className="strength-card">
                        <h3>Market Insights</h3>
                        <p>VCM provides valuable insights into current market trends and salary benchmarks,
                            empowering companies to make informed and strategic hiring decisions.</p>
                    </div>
                </div>
            </section>

            <section className="aim">
                <div className="aim-card">
                    <h3>Our Aim</h3>
                    <p>We aim to provide unmatched recruitment solutions by deeply understanding the unique needs of our clients and the career aspirations of candidates. Our goal is to streamline the hiring process, ensuring the perfect fit every time. By fostering strong, long-term partnerships, we drive mutual success and sustainable growth for both businesses and professionals.</p>
                    <img src={arrow} alt="arrow" className='arrow' />
                </div>
                <img src={mission} alt="vision" />

                <div className="aim-card card2">
                    <img src={arrow} alt="arrow" className='arrow' />
                    <h3>Our Vision</h3>
                    <p>To revolutionize the recruitment landscape, delivering exceptional talent solutions that fuel organizational success and empower individual career growth. We aspire to be the trusted partner for innovative, efficient, and personalized recruitment services, leveraging cutting-edge technology and profound industry expertise to connect the right people with the right opportunities. Our focus is on fostering long-term success for both our clients and candidates.</p>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default About