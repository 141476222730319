import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import recruitment from "../../Image/Recruitment/1.png"

import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";

const RecruitmentService = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className='recruitment-page'>
            <Header />

            <div className="page-banner">
                <h3>Temporary Staffing Solutions</h3>
                <h6><Link to={"/"} >Home</Link>&nbsp;&#x203A; Temporary Staffing Solutions</h6>
            </div>

            <div className="recruitment">
                <img src={recruitment} alt="" />
                <div className="recruitment-body">
                    <p>VCM Recruitments is a leading staffing agency that specializes in providing high-quality temporary staffing solutions to businesses across various industries. With a strong commitment to customer satisfaction, we help organizations manage their workforce efficiently, whether it’s to address short-term needs, cover for employee absences, or support specific projects. Our goal is to deliver flexible, reliable, and highly skilled temporary talent to ensure that your business runs smoothly, even in the most demanding times.</p>

                    <p>Founded with the vision of making the recruitment process easier and more efficient, VCM Recruitments has quickly established a reputation for excellence in the staffing industry. We understand that every business is unique, and we tailor our services to meet the specific needs of each client. Our team of recruitment professionals works closely with you to understand your business, culture, and requirements, ensuring that we provide candidates who are not only skilled but also a good fit for your company.</p>
                    <p><b>What Sets Us Apart</b></p>

                    <p>At VCM Recruitments, we believe in building long-term relationships with our clients and candidates. We take the time to understand the specific challenges that businesses face and offer personalized staffing solutions that provide immediate results and long-term value. We specialize in offering temporary staffing for various sectors, including IT, healthcare, retail, manufacturing, hospitality, construction, finance, and more.</p>

                    <p>We also pride ourselves on the quality of our candidates. Every candidate we recommend undergoes a thorough screening process, including background checks, skills testing, and interviews, to ensure they meet the highest standards. This attention to detail means that when you hire temporary staff from VCM Recruitments, you can trust that they will be reliable, skilled, and capable of contributing to your business from day one.</p>
                </div>


            </div>

            <div className="why">
                <div className="title">
                    <span>Skilled. Flexible. Reliable.</span>
                    <h4>Empowering Your Business with Flexible, Skilled Talente</h4>
                    <p>Our team of industry experts is dedicated to understanding your unique needs and delivering tailored solutions that propel your business forward.</p>
                </div>
                <div className="why-body">
                    <div className="why-card">
                        <h3>Short-Term Staffing</h3>
                        <p>When your business faces an increase in demand or requires temporary help for short-term projects, our short-term staffing solutions ensure you have the right people in place to maintain operations smoothly. Whether it's for a few weeks or a couple of months, we’ll connect you with experienced professionals who can jump right in and deliver results.</p>
                    </div>
                    <div className="why-card">
                        <h3>Emergency & Last-Minute Staffing
                        </h3>
                        <p>Unexpected situations like sudden employee illness, emergency leaves, or unforeseen workload surges can put pressure on your operations. Our emergency staffing service ensures you never have to scramble for qualified professionals. We respond quickly to your needs and provide highly-skilled temporary staff who are ready to step in immediately.</p>
                    </div>
                    <div className="why-card">
                        <h3>Project-Based Staffing</h3>
                        <p>For businesses working on time-sensitive projects or initiatives, our project-based staffing solutions help you meet deadlines and achieve objectives efficiently. We provide professionals with the right expertise for specific tasks, ensuring you have the support needed to get the job done on time and within budget.</p>
                    </div>
                    <div className="why-card">
                        <h3>Staffing for Absences & Vacancies
                        </h3>
                        <p>Whether it's for maternity leave, medical leave, or other types of employee absence, we provide temporary staff to fill in during employee vacancies. This ensures that your business operations continue smoothly without gaps in productivity. We also help during recruitment processes when permanent staff are not yet hired, reducing the strain on your team.</p>
                    </div>
                    <div className="why-card">
                        <h3>Industry-Specific Staffing Solutions
                        </h3>
                        <p>We understand that each industry has its unique requirements. Our recruitment specialists are experienced in a variety of sectors, from healthcare to IT, logistics to retail, ensuring that you receive candidates who not only meet your skills requirements but also understand your industry's dynamics.</p>
                    </div>
                </div>
            </div>

            <div className="temporary-banner">

                <div className="recruitment-banner-card">
                    <h3>Let's Partner to Elevate Your Talent Acquisition</h3>
                    <p>Contact us today to discuss your recruitment needs and learn how our expert team can help you achieve your hiring goals.</p>
                    <Link to={"/contact"}>Contact Us</Link>
                </div>

            </div>

            <Footer />
        </div>
    )
}

export default RecruitmentService