import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import recruitment from "../../Image/Recruitment/1.png"

import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";

const RecruitmentService = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className='recruitment-page'>
            <Header />

            <div className="page-banner">
                <h3>IT/Non-IT Hiring Services</h3>
                <h6><Link to={"/"} >Home</Link>&nbsp;&#x203A; IT/Non-IT Hiring Services</h6>
            </div>

            <div className="recruitment">
                <img src={recruitment} alt="" />
                <div className="recruitment-body">
                    <p>We specialize in bridging the gap between talent and opportunity. With a deep understanding of the evolving job market, we connect businesses with exceptional professionals who bring value and expertise to their roles.</p>
                    <p>Whether you need top-notch IT professionals to drive technological advancements or dynamic Non-IT candidates to enhance operational excellence, we provide customized hiring solutions that align with your unique business objectives.</p>

                    <p>Our Recruitment Services Include:</p>

                    <ol>
                        <li>IT Hiring Solutions
                            <p>We connect you with:</p>
                            <ul>
                                <li>Software Developers</li>
                                <li>IT Architects</li>
                                <li>Network Engineers</li>
                                <li>Data Analysts & Scientists</li>
                                <li>Cloud Specialists</li>
                                <li>And more!</li>
                            </ul>

                        </li>


                        <li>Non-IT Hiring Solutions

                            <p>We excel in placing:</p>
                            <ul>
                                <li>Sales & Marketing Professionals</li>
                                <li>Administrative Staf</li>
                                <li>Customer Support Specialists</li>
                                <li>Human Resources Experts</li>
                                <li>And beyond!</li>
                            </ul>
                        </li>

                    </ol>
                </div>


            </div>
            

            <div className="why">
                <div className="title">
                    <span>Skilled. Flexible. Reliable.</span>
                    <h4>Why Choose Us?</h4>
                    <p></p>
                </div>
                <div className="why-body">
                    <div className="why-card">
                        <h3>Extensive Talent Pool</h3>
                        <p>Access a diverse network of pre-vetted professionals across IT and Non-IT sectors.</p>
                    </div>
                    <div className="why-card">
                        <h3>Tailored Recruitment
                        </h3>
                        <p>We prioritize understanding your unique needs to find candidates who align perfectly with your goals.</p>
                    </div>
                    <div className="why-card">
                        <h3>Fast Turnaround Time
                        </h3>
                        <p>Our efficient process ensures your positions are filled promptly without compromising quality.</p>
                    </div>
                    <div className="why-card">
                        <h3>Cost-Effective Solutions
                        </h3>
                        <p>Save time and resources with our competitive pricing model.</p>
                    </div>
                </div>
            </div>

            <div className="pro">
                <div className="title">
                    <span>Process</span>
                    <h4>Our Recruitment Process</h4>
                    <p></p>
                </div>

                <ul class="process">
                    <li class="process__item">
                        <span class="process__number">1</span>
                        <span class="process__title">Understanding Client Expectations</span>
                        <span class="process__subtitle">Understand client requirements by reviewing the Job Description (JD) and conducting a face-to-face meeting for deeper insights.</span>
                    </li>

                    <li class="process__item">
                        <span class="process__number">2</span>
                        <span class="process__title">Streamline Sourcing with Cross-Team Collaboration</span>
                        <span class="process__subtitle">Distributed queries to other relevant teams to streamline candidate sourcing effectively.</span>
                    </li>

                    <li class="process__item">
                        <span class="process__number">3</span>
                        <span class="process__title">Perfect Match</span>
                        <span class="process__subtitle">Recruiters begin shortlisting candidates with profiles that match 100% of the client's requirements.</span>
                    </li>

                    <li class="process__item">
                        <span class="process__number">4</span>
                        <span class="process__title">Shortlist Complete</span>
                        <span class="process__subtitle">Shares shortlisted profiles with our HR team for thorough screening.</span>
                    </li>

                    <li class="process__item">
                        <span class="process__number">5</span>
                        <span class="process__title">Profiles Selected for Interviews</span>
                        <span class="process__subtitle">Share selected profiles with the client for a face-to-face interview.</span>
                    </li>

                    <li class="process__item">
                        <span class="process__number">6</span>
                        <span class="process__title">Job Offers Extended</span>
                        <span class="process__subtitle">Client extends a job offer to selected candidates.</span>
                    </li>

                    <li class="process__item">
                        <span class="process__number">7</span>
                        <span class="process__title">Strengthen Your Job Descriptions to Improve Hiring</span>
                        <span class="process__subtitle">Document reasons for candidate rejection in the JD to strengthen it, then restart the recruitment cycle.</span>
                    </li>
                </ul>
            </div>

            <div className="recruitment-banner">
                <div className="recruitment-banner-card">
                    <h3>Let's Partner to Elevate Your Talent Acquisition</h3>
                    <p>Contact us today to discuss your recruitment needs and learn how our expert team can help you achieve your hiring goals.</p>
                    <Link to={"/contact"}>Contact Us</Link>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default RecruitmentService