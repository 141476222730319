import React from 'react'
import logo from "../Image/logo/ORIGINAL.png"
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <div>  <footer id='contact'>
            <div className="footer-top">

                {/* <div className="footer-about">
                    <img src={logo} alt="logo" />
                    <h2>Khadyan</h2>
                    <p>A brand based and stated from Gurgaon works under edible oils.</p>
                </div> */}

                <div className="footer-contact">
                    <div className="footer-card">
                        <h3> <div className="svg">
                            <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Call"><path d="m52.5 42.85-2.83-2.82a5.9873 5.9873 0 0 0 -8.48 0l-1.27 1.26a1.9573 1.9573 0 0 1 -1.83.54c-2.1-.47-5.49-1.92-9.74-6.17-4.26-4.26-5.71-7.65-6.18-9.75a1.9573 1.9573 0 0 1 .54-1.83l1.27-1.27a6.01768 6.01768 0 0 0 0-8.48l-2.83-2.83a6.01143 6.01143 0 0 0 -8.48 0l-.87.87a10.64659 10.64659 0 0 0 -2.89 9.89c1.49 6.71 6.41 15.28 11.98 20.85 5.57 5.56 14.14 10.49 20.84 11.98a10.96437 10.96437 0 0 0 2.34.26 10.68538 10.68538 0 0 0 7.56-3.15l.87-.87a6.01143 6.01143 0 0 0 0-8.48z"></path></g></svg>
                        </div>Call Us</h3>
                        <div className="footer-card-text">
                            <a href="tel:918708779363">+91 95609 27047</a>
                        </div>
                    </div>
                    <div className="footer-card">
                        <h3><div className="svg">
                            <svg viewBox="0 -77 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m490.753906 0h-469.507812l234.753906 234.453125zm0 0"></path><path d="m0 21.179688v316.445312l158.425781-158.222656zm0 0"></path><path d="m256 276.851562-76.347656-76.25-158.40625 158.203126h469.507812l-158.40625-158.203126zm0 0"></path><path d="m353.574219 179.402344 158.425781 158.222656v-316.445312zm0 0"></path></svg>
                        </div>Email Us</h3>
                        <div className="footer-card-text">
                            <a href="mailto:vcmentp@gmail.com">vcmentp@gmail.com</a>
                        </div>
                        <div className="footer-card-text">
                            <a href="mailto:office@vcmrecruitments.com">office@vcmrecruitments.com</a>
                        </div>
                    </div>

                    <div className="footer-card">
                        <h3><div className="svg">
                            <svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-105.6 0-191.5 85.9-191.5 191.5 0 34.4 16.2 77.8 49.4 132.7 27.6 45.6 61.8 90.9 91.9 130.8 13.9 18.4 27 35.8 37.1 50.2 3 4.3 7.9 6.8 13.1 6.8s10.1-2.5 13.1-6.8c10.1-14.4 23.2-31.8 37.1-50.2 30.1-39.9 64.2-85.2 91.9-130.8 33.2-54.9 49.4-98.3 49.4-132.7 0-105.6-85.9-191.5-191.5-191.5zm0 280c-48.8 0-88.5-39.7-88.5-88.5s39.7-88.5 88.5-88.5 88.5 39.7 88.5 88.5-39.7 88.5-88.5 88.5z"></path></svg>
                        </div>Location
                        </h3>
                        <div className="footer-location-body">
                            <div className="footer-card-text">
                                <h4>Home Branch</h4>
                                <ul>
                                    <li>#361 Basai sector 9B ,Near fly Over Basai ,Gurgaon (Haryana)</li>
                                </ul>
                            </div>
                            <div className="footer-card-text">
                                <h4>Branch Offices</h4>
                                <ul>
                                    <li>Banipur choke, bawal road
                                        Imt bawal, opp union bank</li>
                                    <li>Office no 223, second floor, ARG Group North Avenue, Road no 9A, VKI Area, Sikar Road, Jaipur (Rajasthan)</li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <div className="footer-copyright">
                <p>&copy; 2024 Aptale Designs. All rights reserved.</p>
            </div>
        </footer></div>
    )
}

export default Footer